module.exports = [{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-catch-links@5.12.0_gatsby@5.12.9/node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-source-wordpress@7.12.3_gatsby-plugin-image@3.12.3_gatsby-plugin-sharp@5.12.3_gatsby-t_p3ysviylhtf5mnmqyfuubumfti/node_modules/gatsby-source-wordpress/gatsby-browser.js'),
      options: {"plugins":[],"url":"https://backend.eksobionics.com/graphql","develop":{"hardCacheData":true,"hardCacheMediaFiles":true,"nodeUpdateInterval":5000},"production":{"allow404Images":true,"hardCacheMediaFiles":false,"allow401Images":false},"debug":{"graphql":{"showQueryVarsOnError":true,"showQueryOnError":true,"copyQueryOnError":false,"panicOnError":false,"onlyReportCriticalErrors":true,"copyNodeSourcingQueryAndExit":false,"writeQueriesToDisk":false,"printIntrospectionDiff":false},"preview":false,"timeBuildSteps":false,"disableCompatibilityCheck":false,"throwRefetchErrors":false},"schema":{"perPage":50,"requestConcurrency":10,"previewRequestConcurrency":5,"timeout":6000000,"queryDepth":15,"circularQueryLimit":5,"typePrefix":"Wp"},"html":{"useGatsbyImage":false,"imageMaxWidth":null,"fallbackImageMaxWidth":1024,"imageQuality":70,"createStaticFiles":true,"generateWebpImages":true,"generateAvifImages":false,"placeholderType":"dominantColor"},"type":{"MediaItem":{"localFile":{"maxFileSizeBytes":100485760,"requestConcurrency":15,"excludeByMimeTypes":[]},"placeholderSizeName":"gatsby-image-placeholder","createFileNodes":true,"lazyNodes":false},"RootQuery":"{ excludeFieldNames: ['viewer', 'node', 'schemaMd5'], },"},"verbose":true,"catchLinks":true},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-manifest@5.12.3_gatsby@5.12.9_graphql@16.8.1/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Gatsby Starter WordPress Blog","short_name":"GatsbyJS & WP","start_url":"/","background_color":"#ffffff","theme_color":"#0b0d12","display":"minimal-ui","icon":"content/assets/eksoicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"aa305f02b7db129e1b4ce8564f936e40"},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-intl@5.10.0_react@18.2.0_typescript@5.2.2/node_modules/gatsby-plugin-intl/gatsby-browser.js'),
      options: {"plugins":[],"path":"/opt/build/repo/src/intl","languages":["en","de"],"defaultLanguage":"en","redirect":false,"redirectDefaultLanguageToRoot":true,"ignoredPaths":[],"fallbackLanguage":"en"},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-gatsby-cloud@5.12.2_gatsby@5.12.9_webpack@5.89.0/node_modules/gatsby-plugin-gatsby-cloud/gatsby-browser.js'),
      options: {"plugins":[],"mergeSecurityHeaders":false},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-google-tagmanager@5.12.3_gatsby@5.12.9_react-dom@18.2.0_react@18.2.0/node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-PMJSC6S","includeInDevelopment":false,"defaultDataLayer":null,"routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/.pnpm/gatsby@5.12.9_babel-eslint@10.1.0_react-dom@18.2.0_react@18.2.0_typescript@5.2.2/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
